/*import React, {useEffect, useState} from "react"
import { Navigate, Link } from 'react-router-dom'
import axios from "axios";
import "./Dashboard.css";
import Header from "./Header.js";
import Marker from "./marker.png"
import Wheel from "./wheel.png"
import Button from "./button.png"
import "./Slots.css"
import { useCookies } from "react-cookie";


const Slots = (props) => { 

  

  const [timeState, setTimeState ] = useState();  
  const [loading, setLoading] = useState(false);
  const [loadingSpin, setLoadingSpin ] = useState(false);
  const [loadingClaim, setLoadingClaim ] = useState(false);
  const [actualDegrees, setactualDegrees ] = useState(0)
  const [ messageState, setMessageState ] = useState("Spin the Wheel to win Cannacoin. 1 spin costs 25 Cannacoin.");
  const [ messageType, setMessageType ] = useState("infoM");
  const [cookies, setCookie] = useCookies();
  let actualToken;

  if(!props.token){ actualToken = localStorage.getItem('token')};
  if(props.token ){ actualToken = props.token}; 
  useEffect( () => {
   

    setLoading(true);
 
        axios.get(process.env.REACT_APP_FRONTEND_URL + 'userdata' , {
             
                 headers: {
                   authorization: 'Bearer ' + actualToken
                 }
                
         }).then((response) => 
         { 
 
           const balance = response.data.balance;  props.setBalance(balance);  setLoading(false);
          }
        
 
      )
     
      console.log(loading)
     }, []);

     
     const handleClick = async () => {

        const actualDegrees = 200;
        let randomDeg;
        let newBalance;
        let message;
        let messageType;

        setLoadingSpin(true);
        props.setBalance(props.balance - 25);

       await axios.get(process.env.REACT_APP_FRONTEND_URL + 'spin' , {
             
            headers: {
              authorization: 'Bearer ' + actualToken
            }
           
    }).then((response) => 
    { 

     newBalance = response.data.balance; randomDeg = response.data.degree; message = response.data.message; messageType = response.data.type;
     });

     let deg = 0;
     const wheel = document.querySelector('.wheel');
     const startButton = document.querySelector('.buttonSlot');
  
   
     
        // Calculate a new rotation between 5000 and 10 000
        console.log(randomDeg + "RANDOMDEG")
        deg = Math.floor(3600 + randomDeg);
        // Set the transition on the wheel
        wheel.style.transition = 'all 10s ease-out';
        // Rotate the wheel
        wheel.style.transform = `rotate(${deg}deg)`;
        // Apply the blur
        wheel.classList.add('blur');
     
   
     wheel.addEventListener('transitionend', () => {
        // Remove blur
        wheel.classList.remove('blur');
        // Enable button when spin is over
       
        // Need to set transition to none as we want to rotate instantly
        wheel.style.transition = 'none';
        // Calculate degree on a 360 degree basis to get the "natural" real rotation
        // Important because we want to start the next spin from that one
        // Use modulus to get the rest value from 360
        const actualDeg = randomDeg;
        // Set the real rotation instantly without animation
        wheel.style.transform = `rotate(${actualDeg}deg)`;
        
        console.log(randomDeg + "RANDOMDEG")
        props.setBalance(newBalance);
        setLoadingSpin(false);
        setMessageState(message);
        setMessageType(messageType);
       
      })

         
     }
  



    
 
    
     if( props.token || localStorage.getItem('token') ){

return ( <span id="spinSpan">
  
 
 <div className="backgroundBoxSlots">
  
<div className="allM">
 <div className={messageType}>
  {messageState}
</div>


 <div id="bodySlots">
 <div className="spinButtonH">
 <img className="marker" src={Marker} />
 </div>
 <div className="bothImage">
<img className="wheel" src={Wheel} /> 
 </div> 
    
     
      
    {props.balance >= 25 ? (  <div className="spinButtonH">
      {loadingSpin === true ? ( <img className="buttonSpinLoad" src={Button} /> ) : ( <img className="buttonSpin" onClick={handleClick} src={Button} /> )}
     </div>) : (null)}
    
    </div>
    

  
     </div>
    </div></span>
)
}

else{ 

  return( <Navigate to="/login" />)
 

  
}
}
export default Slots
*/
import React, { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';
import axios from "axios";
import "./Slots.css";
import { useCookies } from "react-cookie";
import Wheel from "./wheel.png";
import Ball from "./ball.png";

const Slots = (props) => {
  const [winningNumber, setWinningNumber] = useState(null);
  const [colorNumber, setColorNumber] = useState("neutralNumber");
  const [loading, setLoading] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [betTypes, setBetTypes] = useState([]);
  const [messageState, setMessageState] = useState("Place your bet on red or black.");
  const [messageType, setMessageType] = useState("infoM");
  const [cookies] = useCookies();
  const actualToken = props.token || localStorage.getItem('token');

  useEffect(() => {
    setLoading(true);
    axios.get(process.env.REACT_APP_FRONTEND_URL + 'userdata', {
      headers: {
        authorization: 'Bearer ' + actualToken
      }
    }).then((response) => {
      const balance = response.data.balance;
      props.setBalance(balance);
      setLoading(false);
    });
  }, []);

  const handleBet = (type) => {
    if (betTypes.includes(type)) {
      setBetTypes(betTypes.filter((betType) => betType !== type));
    } else {
      setBetTypes([...betTypes, type]);
    }
  };

  const handleClick = async () => {
    if (betTypes.length === 0) {
      setMessageState("Please select at least one bet type.");
      setMessageType("errorM");
      return;
    }
    
    try {
      setLoadingSpin(true);
      props.setBalance(props.balance - (betTypes.length * 25));
  
      const response = await axios.post(process.env.REACT_APP_FRONTEND_URL + 'spin', { bets: betTypes }, {
        headers: {
          authorization: 'Bearer ' + actualToken
        }
      });
  
      const { degree, balance, winNumber, message, type } = response.data;
  
      var resources = [
        { type: 0, degrees: 360},
        { type: 1, degrees: 12 },
        { type: 2, degrees: 36 },
        { type: 3, degrees: 60 },
        { type: 4, degrees: 84},
        { type: 5, degrees: 108 },
        { type: 6, degrees: 132 },
        { type: 7, degrees: 156 },
        { type: 8, degrees: 204 },
        { type: 9, degrees: 228 },
        { type: 10, degrees: 252 },
        { type: 11, degrees: 276},
        { type: 12, degrees: 300 },
        { type: 13, degrees: 324 },
        { type: 14, degrees: 348 },
        { type: 15, degrees: 24 },
        { type: 16, degrees: 48 },
        { type: 17, degrees: 72 },
        { type: 18, degrees: 96 },
        { type: 19, degrees: 120 },
        { type: 20, degrees: 144 },
        { type: 21, degrees: 168 },
        { type: 22, degrees: 192 },
        { type: 23, degrees: 216 },
        { type: 24, degrees: 240 },
        { type: 25, degrees: 264 },
        { type: 26, degrees: 288 },
        { type: 27, degrees: 312 },
        { type: 28, degrees: 336 },
        { type: 29, degrees: 180 },
      
    
     
    
    ];
      const matchingResource = resources.find(resource => resource.type === winNumber);
  
      if (matchingResource) {
        const randomDeg = matchingResource.degrees;
  
        const ball = document.querySelector('.ball');
  
        const deg = Math.floor(3600 + randomDeg);
  
        ball.style.transition = 'all 10s ease-out';
        ball.style.transform = `rotate(${deg}deg)`;
        ball.classList.add('blur');
  
        ball.addEventListener('transitionend', () => {
          ball.classList.remove('blur');
          ball.style.transition = 'none';
          ball.style.transform = `rotate(${randomDeg}deg)`;
  
          props.setBalance(balance);
          setWinningNumber(winNumber);
          setMessageState(message);
          setMessageType('infoM');
          setLoadingSpin(false);
        });
      }
    } catch (error) {
      console.log(error);
      setMessageState("Transaction failed");
      setMessageType("errorM");
    }
  };
  
  if (props.token || localStorage.getItem('token')) {
    return (
      <span id="spinSpan">
        <div className="backgroundBoxRoulette">
          <div className="rouletteContainer">
            <div className="image-container">
              <div className="image-wrapper">
                <img src={Wheel} className="wheel" alt="Wheel" />
              </div>
              <div className="image-wrapper">
                <img src={Ball} className="ball" alt="Ball" />
              </div>
            </div>
          </div>
          <div className="messageBox">
            <div className={messageType}>{messageState}</div>
          </div>
          <div className="betSquares">
            <div
              className={`betSquareRed ${
                loadingSpin && betTypes.length > 0 && !betTypes.includes('red') ? 'notSelected' : ''
              } ${betTypes.includes('red') ? 'selected' : ''}`}
              onClick={!loadingSpin ? () => handleBet('red') : undefined}
            >
              <span>Red</span>
            </div>
            <div
              className={`betSquareBlack ${
                loadingSpin && betTypes.length > 0 && !betTypes.includes('black') ? 'notSelected' : ''
              } ${betTypes.includes('black') ? 'selected' : ''}`}
              onClick={!loadingSpin ? () => handleBet('black') : undefined}
            >
              <span>Black</span>
            </div>
            <div
              className={`betSquareGreen ${
                loadingSpin && betTypes.length > 0 && !betTypes.includes('green') ? 'notSelected' : ''
              } ${betTypes.includes('green') ? 'selected' : ''}`}
              onClick={!loadingSpin ? () => handleBet('green') : undefined}
            >
              <span>Green</span>
            </div>
          </div>
          <div className="spinButton">
            {props.balance >= 25 ? (
              loadingSpin ? (
                <button className="buttonSpinLoad" disabled>Loading...</button>
              ) : (
                <button className="buttonSpin" onClick={handleClick}>Spin</button>
              )
            ) : (
              null
            )}
          </div>
        </div>
      </span>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default Slots;
