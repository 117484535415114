import React, {useState} from 'react';
import "./Rent.css";
import axios from "axios";
import {useForm} from "react-hook-form";
import "./Register.css"
import "./Dashboard.css"
import { Link, Redirect} from "react-router-dom";




const ResetPassword = () => {


    
    const [loading, setLoading ] = useState(false);
    const idurl = window.location.href.split("/").pop();
    const [ urlState, setUrlState ] = useState(idurl);
    const[ submitComplete, setSubmitComplete ] = useState(false);
    const [selectedPassword, setSelectedPassword] = useState("");
    const [isSelectedPassword, setIsSelectedPassword] = useState(false);
    const [selectedPasswordFirst, setSelectedPasswordFirst] = useState("");
    const [isSelectedPasswordFirst, setIsSelectedPasswordFirst] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const changeHandlerPasswordFirst = (event) => {

      setSelectedPasswordFirst(event.target.value);
      setIsSelectedPasswordFirst(true);
      console.log(selectedPassword)
  }
      const changeHandlerPassword = (event) => {

        setSelectedPassword(event.target.value);
        setIsSelectedPassword(true);
        console.log(selectedPassword)
    }
    

    const handleSubmission = async () => {

      if(selectedPassword === selectedPasswordFirst){
      
         setLoading(true);

      
          
         
          
  
         
  
          await axios.post(process.env.REACT_APP_BACKEND_URL +`resetpassword/${idurl}`, { password: selectedPassword}).then(res => {setErrorMessage(res.data.message); setLoading(false)});
      
      
      
      }

      else{ setErrorMessage("passwords do not match")}
      
      }
    
    
    
    
    
  









if(!loading){
   return( 


    <div className="backgroundBox"> 
   
   <div className="formContainerSignUp"> {errorMessage !== "succes" ? (
   <div className="formSignUp">
 <div className='titleMain'>Reset Password</div> 

<label className="form-labelStyle" >password</label>
<input  className="inputStyle" type="password" name="passwordfirst" onChange={changeHandlerPasswordFirst}/>
<br/>
<label className="form-labelStyle"  for="password">retype password</label>
<input  className="inputStyle"  type="password" name="password" onChange={changeHandlerPassword}/>

<br/>
<button className="buttonDashWithdraw"  onClick={handleSubmission}  >Confirm</button> 
<br/>
<div className="error"><p className="errorText">{errorMessage}</p></div>
</div>):( <div className="formSignUp"><div className='arrow'><Link className="registerL" to="/login"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-arrow-left-square" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg></Link></div><div className="titleMain">Password Reset</div>
</div>)}</div></div>
)}


else if(loading){return(<div className="backgroundBox">  <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>) }

}

export default ResetPassword