import React, {useEffect, useState} from "react"
import { Navigate, Link } from 'react-router-dom'
import axios from "axios";
import "./Dashboard.css";
import "./Quiz.css"
import Header from "./Header.js";
import Footer from "./Footer.js";
import cannaLogo from './logo.png';
import Countdown from "./Countdown";
import Welcome from "./Welcome.png";
import { useCookies } from "react-cookie";

const Quiz = (props) => { 


  
  const [cookies, setCookie] = useCookies();
  const [message, setMessage ] = useState("Welcome to your daily quiz to earn Stellar Cannacoin")
  const [timeState, setTimeState ] = useState();  
  const [loading, setLoading] = useState(false);
  const [loadingA, setLoadingA] = useState(false);
  const [loadingClaim, setLoadingClaim ] = useState(false);
  const [accountHolders, setAccountHolders ] = useState([]);
  const [quizLoaded, setQuizLoaded ] = useState(false);
  const [questionTitle, setQuestionTitle ] = useState();
  const [Question, setQuestion ] = useState();
  const [answerA, setAnswerA] = useState();
  const [answerB, setAnswerB] = useState();
  const [answerC, setAnswerC] = useState();
  const [answerD, setAnswerD] = useState();
  const [answered, setAnswered ] = useState(true);
  const [answeredButtonA, setAnsweredButtonA ] = useState("answerBlank")
  const [answeredButtonB, setAnsweredButtonB ] = useState("answerBlank")
  const [answeredButtonC, setAnsweredButtonC ] = useState("answerBlank")
  const [answeredButtonD, setAnsweredButtonD ] = useState("answerBlank")


  let actualToken;

  if(!props.token){ actualToken = localStorage.getItem('token')};
  if(props.token){ actualToken = props.token}; 

  console.log(props.token + actualToken)
  
  console.log(cookies.house_user + "HOUSE")




  useEffect( () => {



 
    setLoading(true);


 
        axios.get(process.env.REACT_APP_FRONTEND_URL + "userdata" , {
             
                 headers: {
                   authorization: 'Bearer ' + actualToken
                 }
                
         }).then((response) => 
         { 
 
           const balance = response.data.balance;  props.setWallet(response.data.wallet); props.setBalance(balance); const time = response.data.time; setTimeState(time);  setLoading(false);
          }

        
        
 
      )

     

      
     
      console.log(loading)
     }, []);

     
     const getQuiz = async () => {

      setLoadingA(true);

      setAnsweredButtonA("answerBlank");
      setAnsweredButtonB("answerBlank");
      setAnsweredButtonC("answerBlank");
      setAnsweredButtonD("answerBlank");

    

      await axios.get(process.env.REACT_APP_FRONTEND_URL + "getquiz" , {
               
          headers: {
            authorization: 'Bearer ' + actualToken
          }
         
  }).then( (res) => {
  
    setMessage(res.data.message); setAnswerA(res.data.answerA);  setAnswerB(res.data.answerB); setAnswerC(res.data.answerC); setAnswerD(res.data.answerD); setQuestion(res.data.question); setQuestionTitle(res.data.questionTitle); setAnswered(false); setLoadingA(false);
  
    })
  
  
  }
  

     const postAnswerA = (answer) => {
      
      console.log("blabla" + answer)
          axios.post(process.env.REACT_APP_FRONTEND_URL + "postanswer", {answer: answer}, {
                   
            headers: {
              authorization: 'Bearer ' + actualToken
            }
           
      }).then( (res) => {
        
      setMessage(res.data.message); 
     
      setAnswered(true); 
      if(res.data.message === "Correct"){
        props.setBalance(res.data.balance);
        setAnsweredButtonA("Agreen")
      }

      else{

        setAnsweredButtonA("Ared")
      }
      
      });
        }

         const postAnswerB = (answer) => {
           
      
          axios.post(process.env.REACT_APP_FRONTEND_URL + "postanswer", {answer: answer}, {
                   
            headers: {
              authorization: 'Bearer ' + actualToken
            }
           
      }).then( (res) => {
        setAnswered(true);
      setMessage(res.data.message);
   

      if(res.data.message === "Correct"){
        props.setBalance(res.data.balance);
        setAnsweredButtonB("Agreen")
      }

      else{

        setAnsweredButtonB("Ared")
      }
      
      });
        }

         const postAnswerC = (answer) => {
         
      
          axios.post(process.env.REACT_APP_FRONTEND_URL + "postanswer", {answer: answer}, {
                   
            headers: {
              authorization: 'Bearer ' + actualToken
            }
           
      }).then( (res) => {
        setAnswered(true); 
      setMessage(res.data.message); 
      

      if(res.data.message === "Correct"){
        props.setBalance(res.data.balance);
        setAnsweredButtonC("Agreen")
      }

      else{

        setAnsweredButtonC("Ared")
      }
      
      });
        }

         const postAnswerD = (answer) => {

        
     
          axios.post(process.env.REACT_APP_FRONTEND_URL + "postanswer", {answer: answer}, {
                   
            headers: {
              authorization: 'Bearer ' + actualToken
            }
           
      }).then( (res) => {
        setAnswered(true); 
      setMessage(res.data.message); 

      if(res.data.message === "Correct"){
        props.setBalance(res.data.balance);
        setAnsweredButtonD("Agreen")
      }

      else{

        setAnsweredButtonD("Ared")
      }
      
      });
        }


    
 
    
if( props.token || localStorage.getItem('token') ){

return ( <div className="mainSpan">
 
 
 <div className="backgroundBoxQuiz">
  

 <div className="messageBox">{message}</div>



{(message === "Come back tomorrow for your next quiz" || message === "Welcome to your daily quiz to earn Stellar Cannacoin" ) ?
 (  loadingA === false ? ( (message === "Come back tomorrow for your next quiz") ? (null ):( <div className="answerBlankB" onClick={getQuiz}> Start</div>)) : (<div className="answerBlank" ><div class="spinner-border" role="status">
  <span class="sr-only"></span>
</div> </div>)): (<div id="quizBox">{answered === false ? (
  <div id="quizBox">
    

  <div id="question">{Question}</div>
<div className="answerBlank" onClick={() => postAnswerA("answerA")} >{answerA}</div>
<div className="answerBlank" onClick={() => postAnswerB("answerB")}>{answerB}</div>
<div className="answerBlank" onClick={() => postAnswerC("answerC")}>{answerC}</div>
<div className="answerBlank"   onClick={() => postAnswerD("answerD")}>{answerD}</div>

{answered === true ? ( <div className="nextB"onClick={getQuiz}> Next</div> ) : ( null )}</div>
):(
  <div id="quizBox">
   
  
  <div id="question">{Question}</div>
<div className={answeredButtonA} >{answerA}</div>
<div className={answeredButtonB} >{answerB}</div>
<div className={answeredButtonC} >{answerC}</div>
<div className={answeredButtonD}>{answerD}</div>

{answered === true ? ( <div className="nextB"onClick={getQuiz}> Next</div> ) : ( null )}</div>

 )}
</div>)}









    
    
    
    
    </div></div>
)
}

else{

  return( <Navigate to="/login" />)
 


}
}
export default Quiz