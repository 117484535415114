import React, { useEffect, useState } from "react";
import { Navigate, Link } from 'react-router-dom';
import axios from "axios";
import "./Dashboard.css";
import "./Deposit.css";
import "./Withdraw.css";
import Header from "./Header.js";
import Footer from "./Footer.js";
import qrCode from "./qrcode.png";
import Countdown from "./Countdown";
import Welcome from "./Welcome.png";
import cannaLogo from './logo.png';
import Lottieplayer from "./87263-check-verde.json";
import Lottie from "lottie-react";
import { useCookies } from "react-cookie";
import QRCode from "react-qr-code";

const Deposit = (props) => {

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: Lottieplayer,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const [fetchingDeposit, setFetchingDeposit] = useState(false);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [memo, setMemo] = useState();
  const [amount, setAmount] = useState();
  const [success, setSuccess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  let actualToken;
  const delay = 15;

  if (!props.token) {
    actualToken = localStorage.getItem('token');
  }
  if (props.token) {
    actualToken = props.token;
  }

  useEffect(() => {
    if (openDeposit) {
      let timer1 = setTimeout(() => handleConfirmation(), delay * 1000);

      // Clear the timeout when the component unmounts
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [fetchingDeposit]);

  const handleClose = async () => {
    setOpenDeposit(false);
    setFetchingDeposit(false);
  }

  const handleCloseSuccess = async () => {
    setOpenSuccess(false);
  }

  const handleConfirmation = async () => {
    if (!fetchingDeposit) {
      setFetchingDeposit(true);
    } else {
      setFetchingDeposit(false);
    }

    await axios.post(process.env.REACT_APP_FRONTEND_URL + 'confirmdeposit', { token: actualToken }, {
      headers: {
        authorization: 'Bearer ' + actualToken
      }
    }).then((res) => {
      setMemo(res.data.memo);
      props.setBalance(res.data.balance);
      let result = res.data.success;
      if (result === true) {
        setSuccess(res.data.success);
        setAmount(res.data.amount);
        setOpenDeposit(false);
        setFetchingDeposit(false);
        setOpenSuccess(true);
      }
    });
  }

  if (props.token || localStorage.getItem('token')) {
    return (
      <span>
        <div className="backgroundBox">
          <div className="depositContainer">
            <button className="buttonDashDeposit" onClick={() => { handleConfirmation(); setSuccess(false); setOpenDeposit(true) }}>
              {fetchingDeposit ? (
                <div className="buttonLoader">Loading...</div> // Display loader when fetchingDeposit is true
              ) : (
                "Deposit"
              )}
            </button>
          </div>

          {openDeposit && !success && memo ? (
            <div id="depositBox">
              <div className="close" onClick={handleClose}>x</div>
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
              <QRCode value={`pay?destination=GBZXX4XPXVUCQAY3XIOXUZ23LU5PSXLJFCWRTTCNFAXWZDSID5MLUCDB&amount=420&memo=${memo}&asset=CANNACOIN&issuer=GBLJ4223KUWIMV7RAPQKBA7YGR4I7H2BIV4KIMMXMQWYQBOZ6HLZR3RQ`} />
              <div className="depositText">
                <div>WALLET ADDRESS:</div>
                <div>GBZXX4XPXVUCQAY3XIOXUZ23LU5PSXLJFCWRTTCNFAXWZDSID5MLUCDB</div>
                <div>MEMO:</div>
                {memo}
              </div>
            </div>
          ) : null}

          {success && openSuccess ? (
            <div id="depositBox">
              <div className="close" onClick={handleCloseSuccess}>x</div>
              <Lottie options={defaultOptions} height={400} width={400} />
              <div className="depositText">
                <div>Deposit was Successful: {amount} Stellar Cannacoin was deposited into your account.</div>
              </div>
            </div>
          ) : null}
        </div>
      </span>
    );
  } else {
    return (<Navigate to="/login" />);
  }
}

export default Deposit;
