import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import Header from './Header.js';
import Footer from './Footer.js';
import Cannalogo from './Cannaland.png';
import './Rent.css';
import './Login.css';
import './Register.css';

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [submitComplete, setSubmitComplete] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [isSelected, setIsSelected] = useState(false);
  const [loginFailed, setLoginFailed] = useState('');
  const [error, setError] = useState('');
  const [selectedEmail, setSelectedEmail] = useState('');
  const [isSelectedEmail, setIsSelectedEmail] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState('');
  const [isSelectedUsername, setIsSelectedUsername] = useState(false);
  const [selectedPassword, setSelectedPassword] = useState('');
  const [isSelectedPassword, setIsSelectedPassword] = useState(false);
  let actualToken;

  if (!props.token) {
    actualToken = localStorage.getItem('token');
  } else {
    actualToken = props.token;
  }

  if (actualToken) {
    return <Navigate to="/" />;
  }

  const changeHandlerEmail = (event) => {
    setSelectedEmail(event.target.value);
    setIsSelectedEmail(true);
  };

  const changeHandlerUsername = (event) => {
    setSelectedUsername(event.target.value);
    setIsSelectedUsername(true);
  };

  const changeHandlerPassword = (event) => {
    setSelectedPassword(event.target.value);
    setIsSelectedPassword(true);
  };

  const handleResend = () => {
    setLoading(true);

    const formData = { username: selectedUsername, password: selectedPassword };
    axios
      .post(process.env.REACT_APP_FRONTEND_URL + 'resend', formData, { withCredentials: true })
      .then((res) => {
        if (res.data.message === 'no') {
          setLoginFailed('no');
          setSubmitComplete(true);
          setLoading(false);
        } else if (res.data.message === 'yes') {
          setLoading(false);
          setError(res.data.error);
          setLoginFailed(res.data.message);
        }
      });
  };

  const handleSubmission = () => {
    setLoading(true);

    const formData = { username: selectedUsername, password: selectedPassword };

    axios
      .post(process.env.REACT_APP_FRONTEND_URL + 'login', formData, { withCredentials: true })
      .then((res) => {
        if (res.data.message === 'no') {
          setLoginFailed('no');
          setSubmitComplete(true);
          setLoading(false);
          props.setBalance(res.data.balance);
          props.setToken(res.data.token);
          localStorage.setItem('token', res.data.token);
          props.setUsername(res.data.username);
          props.setWallet(res.data.wallet);
        } else if (res.data.message === 'yes') {
          setLoading(false);
          setError(res.data.error);
          setLoginFailed(res.data.message);
        }
      });
  };

  if (!submitComplete && !loading) {
    return (
      <span>
        <div className="backgroundLogin">
          <div className="formSignUp">
            <div className="cannalogoBox">
              <div id="imageH">
                <img id="cannaLogo" src={Cannalogo} alt="Cannaland Logo" />
              </div>
            </div>

      

            {loginFailed === 'yes' ? (
              <div className="errorUser">
                {error === 'Please confirm your email address' ? (
                  <div className="confirmationText">
                    A confirmation email has been sent to your email address. Click{' '}
                    <span onClick={handleResend}>here</span> to resend a confirmation email.
                  </div>
                ) : (
                  error
                )}
              </div>
            ) : null}

            <label className="form-labelStyle" htmlFor="username">
              Username
            </label>
            <input className="inputStyle" type="text" name="username" onChange={changeHandlerUsername} />

            <label className="form-labelStyle" htmlFor="password">
              Password
            </label>
            <input className="inputStyle" type="password" name="password" onChange={changeHandlerPassword} />
            <div className="forgot">
              <Link className="forgotL" to="/reset">
                Forgot your password?
              </Link>
            </div>

            <div className="buttonCentreSignUp">
              <button className="buttonB" onClick={handleSubmission}>
                Login
              </button>
            </div>
            <div className="buttonCentreSignUp">
              <Link className="registerL" to="/register">
                <button className="buttonB">Register</button>
              </Link>
            </div>
          </div>
        </div>
      </span>
    );
  } else if (loading && !submitComplete) {
    return (
      <div className="backgroundBox">
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else if (loginFailed === 'yes') {
    return <Navigate to="/login" />;
  } else {
    return <Navigate to="/" />;
  }
};

export default Login;
