
import {useState, useEffect} from 'react';

import {getRemainingTimeUntilMsTimestamp} from './Utils';

const defaultRemainingTime = {
    
 
}

const Countdown = ({timeState, loading, handleClaim, countdownTimestampMs}) => {
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
    },[countdownTimestampMs]);

    function updateRemainingTime(countdown) {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    }

    return(

        remainingTime === defaultRemainingTime ? (<button class="buttonDashH" ><div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div></button>  ) : (   remainingTime.minutes === "00" && remainingTime.seconds == "00" && remainingTime.hours == "00" ? ( <button class="buttonDashH" onClick={handleClaim} >Claim</button> ) : ( <button class="buttonDashH" > <div className="countdown-timer">
 
        <span className="two-numbers">{remainingTime.hours}</span>
      
        <span>:</span>
        <span className="two-numbers">{remainingTime.minutes}</span>
    
        <span>:</span>
        <span className="two-numbers">{remainingTime.seconds}</span>
    
    </div> </button>)

     )

   
      
    );
}

export default Countdown;