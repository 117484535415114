import React, {useState} from "react"
import './Footer.css';

import { Link } from 'react-router-dom';
import Reddit from "./Reddit.png"
import Discord from "./Discord.png"
import Twitter from "./Twitter.png"


const Footer = () => {


    return(<> <div id="footer">


        <div id="logoFooter"><Link to="/"><img id="imageLogoFooter" src="https://i.ibb.co/3syMMm2/My-project-16.png" alt="cannacoin-2-12" border="0"/></Link></div>
       <div id="footerListBox"> <div className="listOneFooter"> <ul><Link to={"/trycanna"}><li>Try Cannacoin</li>
       </Link><Link to={"/staking"}><li>Staking</li></Link><Link to={"/faucet"}><li>Faucet</li></Link></ul></div>

        <div className="listTwoFooter"><ul><Link to={"/whitepaper"}><li>White Paper</li></Link><Link to={"/faq"}><li>FAQ</li></Link><Link to={"/news"}><li>News</li></Link>
        <Link to={"/roadmap"}><li>Roadmap</li></Link>
        </ul></div>
        </div>
       
        
        </div>

        
        <div className="joinCom"><img id="imageCom" src="https://i.ibb.co/vPm15dY/My-project-2022-04-08-T083440-246.png" /></div>
        
        <div class="socialMediaBox2">
       
<a href="https://www.reddit.com/r/StellarCannaCoin/"><img className="imageSocial" src={Reddit} ></img></a>
<a href="https://discord.gg/ttYZ5nagXk"><img className="imageSocial" src={Discord}></img></a>

<a href="https://mobile.twitter.com/StellarCanna"><img className="imageSocial" src={Twitter}></img></a>
            
        </div></>
        
        )
}


export default Footer