import React, {useState, useEffect} from "react"
import './Header.css';

import { Link } from 'react-router-dom';
import axios from "axios";
import Countdown from "./Countdown";
import cannaLogo from './logo.png';
import Hamburger from "./Hamburger.js";
import'./Slots.css'
import Cannalogo from "./Cannaland.png"
import './sunnyfont.otf';

const Header = (props) => {


  let actualToken;

  
  
  
  
  
  const [timeState, setTimeState ] = useState();  
  const [loading, setLoading] = useState(false);
  const [loadingClaim, setLoadingClaim ] = useState(false);
  const [menuState, setMenuState ] = useState('sideMenuOff');
  const [conState, setConstate ] = useState('con');
  const [displayNone, setDisplayNone ] = useState('displayNone');


  
  if(!props.token){ actualToken = localStorage.getItem('token')};
  if(props.token ){ actualToken = props.token}; 
  useEffect( () => {
   
  
    setLoading(true);

   
        axios.get(process.env.REACT_APP_FRONTEND_URL + 'userdata' , {
             

        
                 headers: {
                   authorization: 'Bearer ' + actualToken
                 }
                
         }).then((response) => 
         { 
 
           const balance = response.data.balance;  props.setBalance(balance); const time = response.data.time; setTimeState(time);  setLoading(false);
          }
        
 
      )
     
      console.log(loading)
     }, []);

    

    const handleMenuStateFunction = () => {

      console.log(menuState)

      if(menuState === 'sideMenuOn'){
        setMenuState('sideMenuOff');
        setConstate('con')
        setDisplayNone('displayNone')
      }

      else if(menuState === 'sideMenuOff'){
        setMenuState('sideMenuOn')
        setConstate('conClosed')

        setDisplayNone()
      }
      
    
      
    }

    const handleLogoutFunction = () => {

      console.log(menuState)

      localStorage.removeItem('token') 
      
      window.location.reload();

      if(menuState === 'sideMenuOn'){
        setMenuState('sideMenuOff');
        setConstate('con')
        setDisplayNone('displayNone')
      }

      else if(menuState === 'sideMenuOff'){
        setMenuState('sideMenuOn')
        setConstate('conClosed')

        setDisplayNone()
      }
      
    
      
    }
  

  const handleClaim = async () => {

    setLoadingClaim(true);
   

    axios.post(process.env.REACT_APP_FRONTEND_URL + 'claim' , {ipAddress: null}, {
         
             headers: {
               authorization: 'Bearer ' + actualToken
             }
            
     }).then((response) => 
     { 
          props.setBalance(response.data.updatedBalance); setTimeState(response.data.time); setLoadingClaim(false);
     }
    
  )
  console.log("claim")
    }



    return ( 
    <span>
    <div id="navbar">

     <div id="imageHT">
   <img id="cannaLogoT" src={Cannalogo}/>
     </div>

     <div id="userInfoH">
      <div id="buttonH">  
      
      { !loadingClaim && timeState ? (
      
      <div className="buttonCentreSignUp">
      <Countdown  loading={loading} timeState={timeState} handleClaim={handleClaim} countdownTimestampMs={timeState}/>
      </div>) : (
      
      <div>
      <button class="buttonDashH" ><div class="spinner-border" role="status">
  <span class="sr-only"></span>
</div></button> 
      </div>) 
      
      }
            
      </div>
     
      <div id="balanceH"> 
       <img id="cannaLogoH" src={cannaLogo}  /> 
       <div id="totalH">{props.balance}</div>
      </div>

      <div id="buttonH">
<Hamburger conState={conState} handleMenuState={handleMenuStateFunction}/>

      </div> 

     
      
     </div>
    
    </div> <div className={menuState}>
     
       <div className={displayNone}>
       <div className="bigRedX" onClick={handleMenuStateFunction} >x</div>
         <Link className="link" to="/"> <div onClick={handleMenuStateFunction} className={`menuItem ${displayNone }`}>Home</div></Link>
         <Link className="link" to="/roulette"> <div onClick={handleMenuStateFunction}  className={`menuItem ${displayNone }`}>Roulette</div></Link>
         <Link className="link" to="/quiz"> <div onClick={handleMenuStateFunction} className={`menuItem ${displayNone }`}>Quiz</div></Link>
         <Link className="link" to="/deposit"> <div onClick={handleMenuStateFunction} className={`menuItem ${displayNone }`}>Deposit</div></Link>
         <Link className="link" to="/withdraw"> <div onClick={handleMenuStateFunction} className={`menuItem ${displayNone }`}>Withdraw</div></Link>
         <Link className="link" to="/"> <div onClick={handleLogoutFunction} className={`menuItem ${displayNone }`}>Logout</div></Link>
       </div>
      </div></span>)

  
}

export default Header


