import React, {useState, useEffect} from "react"
import './Hamburger.css';

import { Link } from 'react-router-dom';
import axios from "axios";
import Countdown from "./Countdown";
import cannaLogo from './logo.png';
import'./Slots.css'

const Hamburger = (props) => {


    return(

      

<div className="buttonMenu">
  <div  class="col">
    <div onClick={props.handleMenuState}  class={props.conState}>
      <div  class="bar top"></div>
      <div class="bar middle"></div>
      <div class="bar bottom"></div>
    </div>
  </div>
  
</div>





    )
 }

export default Hamburger
