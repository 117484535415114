import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './logo.svg';
import './App.css';

import Header from './Header.js';
import Register from "./Register.js";
import Login from "./Login.js";
import Withdraw from "./Withdraw.js";
import Deposit from "./Deposit.js";
import Dashboard from './Dashboard.js';
import Quiz from './Quiz.js';
import Confirm from "./Confirm.js";
import Slots from './Slots.js';
import Reset from './Reset.js';
import ResetPassword from './ResetPassword';





function App() {

  const [token, setToken ] = useState();
  const [username, setUsername] = useState("");
  const [balance, setBalance] = useState();
  const [wallet, setWallet ] = useState("")

  const setTokenFunction = (token) => {

    setToken(token);
    console.log('token set to'+ token)
  }
  

  const setWalletFunction = (wallet) => {

    setWallet(wallet);
    console.log('wallet set to'+ wallet)
  }

  const setBalanceFunction = (balance) => {

    setBalance(balance);
    console.log('balance set to'+ balance)
  }

  const setUsernameFunction = (username) => {

    setUsername(username);
    console.log('username set to'+ username)
  }




let localStorageToken = localStorage.getItem('token');
console.log(localStorageToken +"LOCAL")

  return (
   
    <Router>
      {token || localStorageToken ? (<Header setToken={setToken} setBalance={setBalance} balance={balance} username={username} token={token}  />):(null)}
 
      <Routes>
      <Route path="/confirm/:q" element={<Confirm/>}/>
      <Route path="/reset" element={<Reset/>}/>
      <Route path="/resetpassword/:q" element={<ResetPassword/>}/>
      
      <Route path="/withdraw"  element={<Withdraw  setWallet={setWalletFunction}  setBalance={setBalanceFunction} wallet={wallet} balance={balance} username={username} token={token}  setToken={setTokenFunction} setUsername={setUsernameFunction} />} />
      <Route path="/deposit"  element={<Deposit  setWallet={setWalletFunction}  setBalance={setBalanceFunction} wallet={wallet} balance={balance} username={username} token={token}  setToken={setTokenFunction} setUsername={setUsernameFunction} />} />
    <Route path="/register"  element={<Register  setBalance={setBalanceFunction} balance={balance} username={username} token={token}  setToken={setTokenFunction} setUsername={setUsernameFunction} setWallet={setWalletFunction} />} />
    <Route path="/quiz"  element={<Quiz  setBalance={setBalanceFunction} balance={balance} username={username} token={token}  setToken={setTokenFunction} setUsername={setUsernameFunction} setWallet={setWalletFunction} />} />
    <Route path="/" element={<Dashboard setWallet={setWalletFunction} setToken={setTokenFunction} setBalance={setBalanceFunction} balance={balance} token={token} username={username} />} />

    <Route path="/roulette"   element={<Slots  setBalance={setBalanceFunction} balance={balance} token={token} username={username}/>} />
    <Route path="/login"  element={<Login  username={username} token={token} setWallet={setWalletFunction} setBalance={setBalanceFunction}  setToken={setTokenFunction} setUsername={setUsernameFunction}/>} />
    </Routes>
  </Router>




   
  );
}

export default App;
