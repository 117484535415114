import React, {useState} from 'react';
import "./Rent.css";
import "./Reset.css";
import axios from "axios";
import {useForm} from "react-hook-form";

import { Link, Redirect} from "react-router-dom";




const Reset = () => {


    
    const [loading, setLoading ] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const[ submitComplete, setSubmitComplete ] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState("");
    const [isSelectedEmail, setIsSelectedEmail] = useState(false);


      const changeHandlerEmail = (event) => {

        setSelectedEmail(event.target.value);
        setIsSelectedEmail(true);
        console.log(selectedEmail)
    }

    const handleSubmission = async () => {
      
         setLoading(true);

      
          
         
          
  
         
  
          axios.post(process.env.REACT_APP_BACKEND_URL +"reset", { email: selectedEmail}).then( res => {setErrorMessage(res.data.message); setLoading(false)});
      
      
      
       
      
      }
    
    
    
    
    if(!loading){
   return( 

    <div className="backgroundBox">
   
    
   {errorMessage !== "succes" ? ( <div className="formSignUp">
   <div className='titleMain'><div className='arrow'><Link className="registerL" to="/login"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-arrow-left-square" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg></Link></div></div>
<br/>
<div className='info'>
<p2>Please provide the email address that's linked to your account</p2>
</div>
<br/>

<label className="form-labelStyle" for="email">Email</label>
<input className="inputStyle" type="text" name="email" onChange={changeHandlerEmail}/>
<br/>
<button onClick={handleSubmission} className="buttonB" >Send</button> 
<br/>

</div>):(<div className="formSignUp"><div className='arrow'><Link className="registerL" to="/login"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-arrow-left-square" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg></Link></div><div className="titleMain">Please check your email</div>
</div>)}
  </div> 
)}

else if(loading){ return (  <div className="backgroundBox"><div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>)}
    
}

export default Reset