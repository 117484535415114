import React, {useEffect, useState} from "react"
import { Navigate, Link } from 'react-router-dom'
import axios from "axios";
import "./Dashboard.css";
import "./Withdraw.css"
import Header from "./Header.js";
import Footer from "./Footer.js";

import Countdown from "./Countdown";
import Welcome from "./Welcome.png";
import cannaLogo from './logo.png';

import { useCookies } from "react-cookie";
const Withdraw = (props) => { 

  const [cookies, setCookie] = useCookies();

  const [timeState, setTimeState ] = useState();  
  const [loading, setLoading] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw ] = useState(false);
  const [ messageState, setMessageState ] = useState();
  const [ messageType, setMessageType ] = useState();
  
  const [selectedWithdrawAmount, setSelectedWithdrawAmount] = useState("");
  const [isSelectedWithdrawAmount, setIsSelectedWithdrawAmount] = useState(false);

  const changeHandlerWithdrawAmount = (event) => {

    setSelectedWithdrawAmount(event.target.value);
    setIsSelectedWithdrawAmount(true);
}

let actualToken;

  
if(!props.token){ actualToken = localStorage.getItem('token')};
  if(props.token ){ actualToken = props.token}; 

  useEffect( () => {

    setLoading(true);
 
        axios.get(process.env.REACT_APP_FRONTEND_URL + 'userdata' , {
             
                 headers: {
                   authorization: 'Bearer ' + actualToken
                 }
                
         }).then((response) => 
         { 
 
           const balance = response.data.balance;  props.setWallet(response.data.wallet); props.setBalance(balance); const time = response.data.time; setTimeState(time);  setLoading(false);
          }
        
 
      )
     
      console.log(props.wallet)
     }, []);
     

     const getData = async () => {
      const res = await axios.get('https://geolocation-db.com/json/')
   
      return res.data.IPv4;
    }

    
    const handleWithdraw = () => {
     
  
     
         setLoadingWithdraw(true);
         console.log(loadingWithdraw + "LOADINGWITHDRAW")
  
    
  
         
  
  
       
      
  
  
  
      axios.post(process.env.REACT_APP_FRONTEND_URL + 'withdraw', { withdrawAmount: selectedWithdrawAmount, token: actualToken }, { headers: {
        authorization: 'Bearer ' + actualToken
      }
     }).then( (res) => { props.setBalance(res.data.newBalance); setMessageState(res.data.message); setMessageType(res.data.messageType);    
      setLoadingWithdraw(false)})
      
   
  
       
      
      }


    
 
    
if(props.token || localStorage.getItem('token')  ){

return ( 


<span>

 
 <div className="backgroundBox">
 <div className={messageType}>
  {messageState}
</div>
<div className="withdrawContainer">
<label  className="form-labelStyle" for="withdrawAmount">Withdraw Amount</label>
<div className="iconInputContainer"><div className="iconInput"><img id="cannaLogoW" src={cannaLogo} /></div><input className="inputStyleW" type="text" name="withdrawAmount" onChange={changeHandlerWithdrawAmount}/></div>

<label  className="form-labelStyle" for="withdrawAmount">Your Wallet Address</label>
<div className="iconInputContainer"><div className="iconInput2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock-fill" viewBox="0 0 16 16">
  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
</svg></div><input className="inputStyleW2" value={props.wallet} type="text" name="withdrawAmount" /></div>

{loadingWithdraw === true ? (  <button class="buttonDashWithdraw" ><div class="spinner-border" role="status">
  <span class="sr-only"></span>
</div></button> ) : ( selectedWithdrawAmount > 0 ? (<button className="buttonDashWithdraw" onClick={handleWithdraw}>Withdraw</button>) : (<button className="buttonDashWithdrawShaded">Withdraw</button>))}

 </div>
   
    
    
    
    
    </div></span>
)
}

else{

  return( <Navigate to="/login" />)
 

    

  
}
}
export default Withdraw