// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Ink.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a  {
  text-decoration: none;
  color: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Ink";
  src: local("Ink"),
   url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");

  }

  .app {
    display: flex;
    padding: 30px;
  }
  
  .game {
    width: 560px;
    height: 560px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .game img {
    width: 70px;
    height: 70px;
  }
  `, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE;aACW;AACb;;AAEA;EACE,kBAAkB;EAClB;6DAC0C;;EAE1C;;EAEA;IACE,aAAa;IACb,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\na  {\n  text-decoration: none;\n  color: inherit;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@font-face {\n  font-family: \"Ink\";\n  src: local(\"Ink\"),\n   url(\"./fonts/Ink.ttf\") format(\"truetype\");\n\n  }\n\n  .app {\n    display: flex;\n    padding: 30px;\n  }\n  \n  .game {\n    width: 560px;\n    height: 560px;\n    display: flex;\n    flex-wrap: wrap;\n  }\n  \n  .game img {\n    width: 70px;\n    height: 70px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
