import React, { useEffect, useState, useRef } from "react";
import { Navigate } from 'react-router-dom';
import axios from "axios";
import "./Dashboard.css";
import Header from "./Header.js";
import Footer from "./Footer.js";
import cannaLogo from './logo.png';
import Countdown from "./Countdown";
import Welcome from "./Welcome.png";
import { useCookies } from "react-cookie";
import io from 'socket.io-client';


const socket = io('https://agile-mesa-56456.herokuapp.com'); // Replace with the URL of your Socket.io server

const Dashboard = (props) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [canSend, setCanSend] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [onlineUsersCount, setOnlineUsersCount] = useState(0);
  const sendTimeoutRef = useRef(null);
  const chatBoxRef = useRef(null);
  const actualToken = props.token || localStorage.getItem('token');
  
  useEffect(() => {
    socket.on('chat-message', function(data) {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, data];
        if (updatedMessages.length > 8) {
          updatedMessages.shift();
        }
        return updatedMessages;
      });
    });

    socket.on('user-count', function (count) {
      setOnlineUsersCount(count);
    });

    return () => {
      socket.off('chat-message');
      socket.off('user-count');
    };
  }, []);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('https://agile-mesa-56456.herokuapp.com/api/messages');
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();

    const interval = setInterval(fetchMessages, 5000); // Fetch messages every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = () => {
    if (message.trim() === "") {
      return;
    }

    if (!canSend) {
      return;
    }

    setIsLoading(true);

    const username = props.username;
    const color = getRandomColor();
    const time = new Date().toUTCString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
console.log(username + "USERNAME");
    const data = {
      username,
      color,
      message,
      time,
    };

    axios.post('https://agile-mesa-56456.herokuapp.com/api/messages', data, {
      headers: {
        authorization: 'Bearer ' + actualToken
      }
    })
      .then(() => {
        socket.emit('chat-message', data);
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending message:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setCanSend(false);
    clearTimeout(sendTimeoutRef.current);
    sendTimeoutRef.current = setTimeout(() => {
      setCanSend(true);
    }, 6000);
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  if (props.token || localStorage.getItem('token')) {
    return (
      <div className="mainSpan">
        <div className="backgroundBox">
          <div className="chatBoxHolder">
            <div className="onlineUsersCount">{onlineUsersCount}</div>
            <div ref={chatBoxRef} className="chatBox">
              {messages && messages.length > 0 ? (
                <>
                  {messages.map((data, index) => (
                    <div key={index} style={{ color: data.color }}>
                      <span>{data.time.split('T')[0]}</span>
                      <span>{data.time.split('T')[1].slice(0, -5)}</span>
                      <span>{data.username}: {data.message}</span>
                    </div>
                  ))}
                </>
              ) : (
                <div className="noMessages">No messages available</div>
              )}
            </div>
            <div className="inputBox">
              <input
                type="text"
                value={message}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Type a message..."
              />
              <button onClick={sendMessage} disabled={!canSend}>
                {isLoading ? <span>Loading...</span> : <span>Send</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default Dashboard;
