import React, {useState, useEffect} from 'react';
import "./Rent.css";
import axios from "axios";
import {useForm} from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, Navigate } from "react-router-dom";
import "./Register.css"



const Register = (props) => {

   


    const recaptchaRef = React.createRef();



   const [loading, setLoading ] = useState(false);
    const[ submitComplete, setSubmitComplete ] = useState(false);


    const [isSelected, setIsSelected] = useState(false);
    const [userExists, setUserExists ] = useState("");




    const [emailErr, setEmailErr ] = useState({});
    const [userNameErr, setUserNameErr ]= useState({});
    const [passwordErr, setPasswordErr ]= useState({});
    const [walletErr, setWalletErr ]= useState({});




    const [selectedEmail, setSelectedEmail] = useState("");
    const [isSelectedEmail, setIsSelectedEmail] = useState(false);

    const [selectedUsername, setSelectedUsername] = useState("");
    const [isSelectedUsername, setIsSelectedUsername] = useState(false);

    const [selectedPassword, setSelectedPassword] = useState("");
    const [isSelectedPassword, setIsSelectedPassword] = useState(false);
    
    const [selectedWallet, setSelectedWallet] = useState("");
    const [isSelectedWallet, setIsSelectedWallet] = useState(false);
    
    const [selectedWalletTwo, setSelectedWalletTwo] = useState("");
    const [isSelectedWalletTwo, setIsSelectedWalletTwo] = useState(false);

    const [captchaState, setCaptchaState ] = useState("");
    
    let actualToken;

    if(!props.token){ actualToken = localStorage.getItem('token')};
    if(props.token ){ actualToken = props.token}; 
  
    
   if( actualToken){  return <Navigate to="/" />}

   function onChange(value) {
    console.log("Captcha value:", value);
    setCaptchaState(value);
    console.log("Captcha State:" + captchaState);
  }

    const changeHandlerEmail = (event) => {

        setSelectedEmail(event.target.value);
        setIsSelectedEmail(true);
    }

    const changeHandlerUsername = (event) => {

        setSelectedUsername(event.target.value);
        setIsSelectedUsername(true);
    }
    const changeHandlerPassword= (event) => {

        setSelectedPassword(event.target.value);
        setIsSelectedPassword(true);
    }
 
    const changeHandlerWallet= (event) => {

        setSelectedWallet(event.target.value);
        setIsSelectedWallet(true);
    }

    const changeHandlerWalletTwo= (event) => {

        setSelectedWalletTwo(event.target.value);
        setIsSelectedWalletTwo(true);
    }


    const handleSubmission = async () => {

      const isValid = formvalidation();
      const recaptchaValue = await recaptchaRef.current.getValue();

      if(isValid){
       setLoading(true);

		const formData = { email: selectedEmail, username: selectedUsername,password: selectedPassword, wallet: selectedWallet, token: recaptchaValue  }

       


     
		



		axios.post(process.env.REACT_APP_FRONTEND_URL + 'register', formData).then( res => {if(res.data.message === "no") { setUserExists("no"); setSubmitComplete(true); setLoading(false); } else if(res.data.message === "yes"){ setLoading(false);  setUserExists(res.data.message); console.log(res.data.token); console.log(res.data.result)} })
    
    
    

     
    
    }}

    const formvalidation = () => {
 
    const emailErr = {};
    const userNameErr = {};
    const passwordErr = {};
    const walletErr = {};




    let isValid = true;

   
    if(!selectedEmail.includes("@"))
    {emailErr.invalid = "Please insert a valid email address"; isValid = false}
    if(selectedUsername.trim().length < 6 )
    {userNameErr.userNameShort = "Please choose a username with more then 5 characters"; isValid = false}
    if(selectedPassword.trim().length < 8 )
    {passwordErr.passwordShort = "Please choose a password with more then 7 characters"; isValid = false}
    if(selectedWallet.trim().length < 10 )
    {walletErr.walletShort = "Please choose a valid wallet address"; isValid = false}
   if(selectedWallet !== selectedWalletTwo)
   {walletErr.walletShort = "Please make sure both wallet addresses are the same"; isValid = false}





    setEmailErr(emailErr);
    setUserNameErr(userNameErr);
    setPasswordErr(passwordErr);
    
    setWalletErr(walletErr);
    

    return isValid;

    }
			
	
if(!submitComplete && !loading){
	return(

     
        
        
        <div className="backgroundBox">   
   
   <div className="formSignUp">
       

       {userExists === "yes" ? (<div className="errorUser">username and/or email is already taken</div>) : (null)}
        
  <div className='titleMain'><div className='arrow'><Link className="registerL" to="/login"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-arrow-left-square" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg></Link></div></div> 
    
       
        <div className="errorBox">
        {Object.keys(emailErr).map( (key) => {return <div className="errorMessageS">{emailErr[key]}</div>})}
        </div>
        <label className="form-labelStyle" for="email">Email</label>
        <input className="inputStyle" type="text" name="email" onChange={changeHandlerEmail}/>
        <div className="errorBox">
        {Object.keys(userNameErr).map( (key) => {return <div className="errorMessageS">{userNameErr[key]}</div>})}
        </div>
        <label className="form-labelStyle"for="username">Username</label> 
        <input className="inputStyle" type="text" name="username" onChange={changeHandlerUsername}/>
        <div className="errorBox">
        {Object.keys(passwordErr).map( (key) => {return <div className="errorMessageS">{passwordErr[key]}</div>})}
        </div>
        <label  className="form-labelStyle" for="password">Password</label>
        <input  className="inputStyle" type="password" name="password" onChange={changeHandlerPassword}/>

        <div className="errorBox">
        {Object.keys(walletErr).map( (key) => {return <div className="errorMessageS">{walletErr[key]}</div>})}
        </div>
        <label  className="form-labelStyle" for="password">Wallet Address</label>
        <input  className="inputStyle" type="text" name="wallet" onChange={changeHandlerWallet}/>

        <label  className="form-labelStyle" for="password">Confirm Wallet Address</label>
        <input  className="inputStyle" type="text" name="wallet" onChange={changeHandlerWalletTwo}/>
       
        <ReCAPTCHA
                 style={{ display: "flex", justifyContent: "center", margin: "20px", maxwidth: "100%" }}
    sitekey="6LfZynggAAAAAIxHseQ16rvkO1GcuyEeAUOBQStY"
    onChange={onChange}
    ref={recaptchaRef}
  />
      
			
			<div className="buttonCentreSignUp">
				<button class="buttonB" onClick={handleSubmission}>Create Account</button> 
			</div></div>
          

        
        </div>
        
        
       
	)}

    else if (loading && !submitComplete) { return <div className="backgroundBox">  <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
    else if(userExists === "yes"){ return <Navigate to="/register" />}
    else { return < Navigate to="/login" /> }

    
}

export default Register