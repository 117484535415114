// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info{
    color: #333333;
    font-size: 20px;
    padding: 20px;
    min-height: 100px;
    height: 100px;
    align-self: center;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
}

.bi-arrow-left-circle{
width: 100px;
height: 100px;
color: white;

}

.arrow{
    color: white;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Reset.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;AACf;;AAEA;AACA,YAAY;AACZ,aAAa;AACb,YAAY;;AAEZ;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".info{\n    color: #333333;\n    font-size: 20px;\n    padding: 20px;\n    min-height: 100px;\n    height: 100px;\n    align-self: center;\n    display: flex;\n    justify-content: center;\n    text-align: center;\n    align-items: center;\n    width: 100%;\n}\n\n.bi-arrow-left-circle{\nwidth: 100px;\nheight: 100px;\ncolor: white;\n\n}\n\n.arrow{\n    color: white;\n    width: 100%;\n    height: 100px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
