import React, {useEffect, useState} from "react"
import axios from "axios";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Link } from 'react-router-dom';

const Confirm = (props) => {
    const idurl = window.location.href.split("/").pop();
    const [ urlState, setUrlState ] = useState(idurl);
    const [message, setMessage ] = useState();
    const [ loading, setLoading ] = useState(true);




useEffect(  () => {
    
 
axios.post(process.env.REACT_APP_BACKEND_URL +`confirm/${urlState}`, {
				
        headers: {
          authorization: 'Bearer ' + props.token
        }
       
      }).then((res) => { setMessage(res.data.message);  setLoading(false)})


     

}, []);


if(!loading){return  <div className="backgroundBox"><div className="formSignUp"><div className='arrow'><Link className="registerL" to="/login"><svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" class="bi bi-arrow-left-square" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm11.5 5.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
</svg></Link></div><div className="titleMain">{message}</div>
</div></div>}
else { return <div className="backgroundBox"><div className="listContainerLoading"><div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></div>}}

export default Confirm;